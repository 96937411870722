@import "../../assets/scss/colors";

.edgebutton {
  width: 20px;
  height: 20px;
  background: #969b9f;
  border: 1px solid #fff;
  color: #FFFFFF;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  line-height: 1;
}

.edgebutton:hover {
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}
