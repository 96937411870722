.note-node {
  position: relative;
  width: 250px;
  background: #c1e4ff;
  padding: 2px;
  border-radius: 8px;

  .note-node-input-txt {
    width: 100%;
    background: #c1e4ff;
    border: none;
  }

  .transparent-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: grab;
    background: transparent;
    z-index: 10;
  }
}