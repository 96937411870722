.workflow-node {
  .react-flow__node-toolbar {
    display: none;
  }
}

.block-title{
  background: transparent;
}

.block-title:hover {
  background: #d2d8df;
  cursor: text;
}

/* Top Connectors */
.connector.top {
  position: absolute;
  top: 0;
  left: 50%;
  width: 4px;
  height: 4px !important;
}

/* Bottom Connectors */
.connector.bottom {
  position: absolute;
  //bottom: -10px;
  left: 50%;
  width: 4px;
  height: 4px !important;
}

/* Left Connectors */
.connector.left-top {
  position: absolute;
  top: 20%;
  left: 5px;
}

.connector.left-bottom {
  position: absolute;
  top: 80%;
  left: 5px;
}

/* Right Connectors */
.connector.right-top {
  position: absolute;
  top: 20%;
  right: 5px;
}

.connector.right-bottom {
  position: absolute;
  top: 80%;
  right: 5px;
}
